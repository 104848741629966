import React, { useState, useEffect } from 'react';
import documentIcon from './document.png';
import photoIcon from './image.png';
import blueprintIcon from './blueprintIcon.png';
import chevronLeft from './chevronLeft.png';
import chevronRight from './chevronRight.png';
import chevronDown from './chevronDown.png';
import redpin from './redpin.png';
import bluepin from './bluepin.png';
import keyTopFrame from './KeyTopFrame.png';
import keyBottomFrame from './KeyBottomFrame.png';
import keyTurning from './KeyTurning.gif';
import wrenchTopFrame from './WrenchTopFrame.png';
import wrenchBottomFrame from './WrenchBottomFrame.png';
import wrenchTurning from './WrenchTurning.gif';
import AnimatedGif from '../animatedGif';
import EditJob from '../editjob';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import JobDocumentForm from '../jobDocumentForm';
import JobPhotoForm from '../jobPhotoForm';
import JobBlueprintsForm from '../jobBlueprintsForm';
import TripNoteForm from '../tripNoteForm';
import AddressLink from '../addressLink';
import MetricForm from '../metricForm';
import collapseIcon from './collapseIcon.png';
import expandIcon from './expandIcon.png';
import { parseTimeWindow } from '../utils';
import './index.css';
import { useSelection } from './../selectionContext';

const JobData = ({ data, refreshTables }) => {
    const [visibleJobIds, setVisibleJobIds] = useState([]);
    const [documentFormModalIsOpen, setDocumentFormModalIsOpen] = useState(false);
    const [photoFormModalIsOpen, setPhotoFormModalIsOpen] = useState(false);
    const [documentListModalIsOpen, setDocumentListModalIsOpen] = useState(false);
    const [blueprintsListModalIsOpen, setBlueprintsListModalIsOpen] = useState(false);
    const [blueprintsFormModalIsOpen, setBlueprintsFormModalIsOpen] = useState(false);
    const [currentJobDocuments, setCurrentJobDocuments] = useState([]);
    const [currentJobBluePrints, setCurrentJobBluePrints] = useState([]);
    const [photoCarouselModalIsOpen, setPhotoCarouselModalIsOpen] = useState(false);
    const [tripNoteModalIsOpen, setTripNoteModalIsOpen] = useState(false);
    const [metricModalIsOpen, setMetricModalIsOpen] = useState(false);
    const [currentJobPhotos, setCurrentJobPhotos] = useState([]);
    const [actionItems, setActionItems] = useState({});
    const { getSelectedIds, handleCheckboxChange } = useSelection();

    // using notes state to prevent calling a request of each input change
    const [customerReports, setCustomerReports] = useState([]);
    const [internalNotes, setInternalNotes] = useState([]);
    //const [currentTripIndex, setCurrentTripIndex] = useState(0);
    console.log(customerReports);
    useEffect(() => {
        if (data && data.length > 0) {
            const initialActionItems = {};
            data.forEach(job => {
                const latestTripIndex = job.trips.length - 1;
                initialActionItems[job.id] = {
                    currentTripIndex: latestTripIndex,
                    actionItem: job.trips[latestTripIndex]?.action_item || "Action Item needed"
                };
            });
            setActionItems(initialActionItems);

            const reports = data.map(job => job.customer_reported);
            setCustomerReports(reports);

            const notes = data.map(job => job.internal_notes);
            setInternalNotes(notes);
        }
    }, [data]);

    function openTripNoteModal() {
        setTripNoteModalIsOpen(true);
    }

    function closeTripNoteModal() {
        setTripNoteModalIsOpen(false);
    }

    function openMetricModal() {
        setMetricModalIsOpen(true);
    }

    function closeMetricModal() {
        setMetricModalIsOpen(false);
    }

    function openPhotoCarouselModal(photos) {
        setCurrentJobPhotos(photos);
        setPhotoCarouselModalIsOpen(true);
    }

    function closePhotoCarouselModal() {
        setPhotoCarouselModalIsOpen(false);
    }

    const getOrdinal = (num) => {
        const suffixes = ["th", "st", "nd", "rd"];
        const v = num % 100;
        return num + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    };

    const toggleVisibility = (jobId) => {
        setVisibleJobIds((prevVisibleJobIds) =>
            prevVisibleJobIds.includes(jobId)
                ? prevVisibleJobIds.filter((id) => id !== jobId)
                : [...prevVisibleJobIds, jobId]
        );
    };

    function openDocumentFormModal() {
        setDocumentFormModalIsOpen(true);
    }
  
    function closeDocumentFormModal() {
        setDocumentFormModalIsOpen(false);
    }

    function openBluePrintFormModal() {
        setBlueprintsFormModalIsOpen(true);
    }
  
    function closeBluePrintFormModal() {
        setBlueprintsFormModalIsOpen(false);
    }

    function openPhotoFormModal() {
        setPhotoFormModalIsOpen(true);
    }
  
    function closePhotoFormModal() {
        setPhotoFormModalIsOpen(false);
    }

    function openDocumentListModal(documents) {
        const port = 8443;
        setCurrentJobDocuments(documents.map(doc => {
            return {
                ...doc,
                document_url: `${window.location.protocol}//${window.location.hostname}:${port}${doc.document}`
            }
        }));
        setDocumentListModalIsOpen(true);
    }
    
    function closeDocumentListModal() {
        setDocumentListModalIsOpen(false);
    }

    function openBluePrintsListModal(blueprints) {
        const port = 8443;
        setCurrentJobBluePrints(blueprints.map(doc => {
            return {
                ...doc,
                blueprint_url: `${window.location.protocol}//${window.location.hostname}:${port}${doc.blueprint}`
            }
        }));
        setBlueprintsListModalIsOpen(true);
    }
    
    function closeBluePrintsListModal() {
        setBlueprintsListModalIsOpen(false);
    }

    function handleChangeCompletionDate(event, job) {
        const token = localStorage.getItem('access_token');
        const url = job.id 
            ? `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/${job.id}/` // Adjust the URL for updating the job
            : `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/`; // Adjust the URL for adding a new job
        const method = job.id ? 'PUT' : 'POST'; // Use 'PUT' to update and 'POST' to create
        const newCompletionDate = event.target.value;

        // Update the completion date for the job with jobId
        const newJabData = {
            ...job,
            status: 'CMP',
            completion_date: newCompletionDate
        };

        fetch(url, { // Adjust this URL to your jobs endpoint
            method: method,
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }),
            body: JSON.stringify(newJabData),
        })
        .then(response => {
            if (!response.ok) {
                // Convert non-2xx HTTP responses into errors so the catch block can handle them
                return response.json().then(data => Promise.reject(data));
            }
            return response.json();
        })
        .then(data => {
            // notify(`Successfully Saved!`);
            refreshTables();
        })
        .catch(error => {
            let errorMessage = '';

            Object.keys(error).forEach(key => {
                // Check if the property is an array and has at least one message
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    // Append the first error message of each field to the errorMessage string
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            //if (error.code == 'token_not_valid') {
            //    logout();
            //}

            //notify(`Error adding job: ${errorMessage}`);
            console.error('Error adding job:', error);
        });
    }

    function parseTripStatus(status) {
        switch(status) {
            case 'UNS':
                return 'Unscheduled';
            case 'SCH':
                return 'Scheduled';
            case 'OTW':
                return 'On The Way';
            case 'ONS':
                return 'On Site';
            case 'CMP':
                return 'Complete';
            default:
                return '';
        }
    }

    function keyClickCallback(job, currentTripIndex) {
        const token = localStorage.getItem('access_token');
        let url = '';
        let method = '';
        let updatedTripData = {};

        if (parseTripStatus(job.trips[currentTripIndex].status) === 'Complete') {
            url = `${window.location.protocol}//${window.location.hostname}:8443/api/trips/`;
            method = 'POST';

            const newTripData = {
                job: job.id,
                start_trip: new Date().toISOString(),
                scheduled_date: job.trips[currentTripIndex].scheduled_date,
                status: 'OTW'
            };

            fetch(url, {
                method: method,
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                body: JSON.stringify(newTripData),
            })
            .then(response => {
                if (!response.ok) {
                    // Convert non-2xx HTTP responses into errors so the catch block can handle them
                    return response.json().then(data => Promise.reject(data));
                }
                return response.json();
            })
            .then(data => {
                // notify(`Successfully Saved!`);
                currentTripIndex++;
                refreshTables();
            })
            .catch(error => {
                let errorMessage = '';
    
                Object.keys(error).forEach(key => {
                    // Check if the property is an array and has at least one message
                    if (Array.isArray(error[key]) && error[key].length > 0) {
                        // Append the first error message of each field to the errorMessage string
                        errorMessage += `${key}: ${error[key][0]}\n`;
                    }
                });
    
                //if (error.code == 'token_not_valid') {
                //    logout();
                //}
    
                //notify(`Error adding job: ${errorMessage}`);
                console.error('Error adding job:', error);
            });
        }

        if (parseTripStatus(job.trips[currentTripIndex].status) === 'Scheduled') {
            url = `${window.location.protocol}//${window.location.hostname}:8443/api/trips/${job.trips[currentTripIndex].id}/`;
            method = 'PUT';

            updatedTripData = {
                ...job.trips[currentTripIndex],
                start_trip: new Date().toISOString(),
                status: 'OTW'
            }
    
            fetch(url, {
                method: method,
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                body: JSON.stringify(updatedTripData),
            })
            .then(response => {
                if (!response.ok) {
                    // Convert non-2xx HTTP responses into errors so the catch block can handle them
                    return response.json().then(data => Promise.reject(data));
                }
                return response.json();
            })
            .then(data => {
                // notify(`Successfully Saved!`);
                refreshTables();
            })
            .catch(error => {
                let errorMessage = '';
    
                Object.keys(error).forEach(key => {
                    // Check if the property is an array and has at least one message
                    if (Array.isArray(error[key]) && error[key].length > 0) {
                        // Append the first error message of each field to the errorMessage string
                        errorMessage += `${key}: ${error[key][0]}\n`;
                    }
                });
    
                //if (error.code == 'token_not_valid') {
                //    logout();
                //}
    
                //notify(`Error adding job: ${errorMessage}`);
                console.error('Error adding job:', error);
            });
        }
        
    }

    function wrenchClickCallback(job, currentTripIndex) {
        const token = localStorage.getItem('access_token');
        const url = `${window.location.protocol}//${window.location.hostname}:8443/api/trips/${job.trips[currentTripIndex].id}/`
        const method = 'PUT';
        let updatedTripData = {};

        if (parseTripStatus(job.trips[currentTripIndex].status) === 'On Site') {
            updatedTripData = {
                ...job.trips[currentTripIndex],
                leave_site: new Date().toISOString(),
                status: 'CMP'
            }
    
            fetch(url, { // Adjust this URL to your jobs endpoint
                method: method,
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                body: JSON.stringify(updatedTripData),
            })
            .then(response => {
                if (!response.ok) {
                    // Convert non-2xx HTTP responses into errors so the catch block can handle them
                    return response.json().then(data => Promise.reject(data));
                }
                return response.json();
            })
            .then(data => {
                // Open up the model to record a trip note
                openTripNoteModal();

                // notify(`Successfully Saved!`);
                refreshTables();
            })
            .catch(error => {
                let errorMessage = '';
    
                Object.keys(error).forEach(key => {
                    // Check if the property is an array and has at least one message
                    if (Array.isArray(error[key]) && error[key].length > 0) {
                        // Append the first error message of each field to the errorMessage string
                        errorMessage += `${key}: ${error[key][0]}\n`;
                    }
                });
    
                //if (error.code == 'token_not_valid') {
                //    logout();
                //}
    
                //notify(`Error adding job: ${errorMessage}`);
                console.error('Error adding job:', error);
            });
        }

        if (parseTripStatus(job.trips[currentTripIndex].status) === 'On The Way') {
            updatedTripData = {
                ...job.trips[currentTripIndex],
                arrive_on_site: new Date().toISOString(),
                status: 'ONS'
            }
    
            fetch(url, { // Adjust this URL to your jobs endpoint
                method: method,
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                body: JSON.stringify(updatedTripData),
            })
            .then(response => {
                if (!response.ok) {
                    // Convert non-2xx HTTP responses into errors so the catch block can handle them
                    return response.json().then(data => Promise.reject(data));
                }
                return response.json();
            })
            .then(data => {
                // notify(`Successfully Saved!`);
                refreshTables();
            })
            .catch(error => {
                let errorMessage = '';
    
                Object.keys(error).forEach(key => {
                    // Check if the property is an array and has at least one message
                    if (Array.isArray(error[key]) && error[key].length > 0) {
                        // Append the first error message of each field to the errorMessage string
                        errorMessage += `${key}: ${error[key][0]}\n`;
                    }
                });
    
                //if (error.code == 'token_not_valid') {
                //    logout();
                //}
    
                //notify(`Error adding job: ${errorMessage}`);
                console.error('Error adding job:', error);
            });
        }
    }

    

    // Function to find the send date of the invoice
    const getInvoiceSendDate = (job) => {
        const invoice = job.invoices.find(invoice => invoice.invoice_type === 'INV');

        if (invoice) {
            return invoice.invoice_sent ? invoice.invoice_sent_date : 'TBD';
        } else {
            return 'TBD';
        }
    };

    const addDaysToDate = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result.toLocaleDateString('en-CA');
    };

    const getKeyButtonText = (status) => {
        switch (status) {
            case 'Scheduled':
                case 'Complete':
                return 'On The Way';
            case 'Arrived':
            default:
                return '';
        }
    };

    const getWrenchButtonText = (status) => {
        switch (status) {
            case 'On The Way':
                return 'Arrived At Job';
            case 'On Site':
                return 'Leave Job Site';
            default:
                return '';
        }
    };

    const isKeyLocked = (status) => {
        switch (parseTripStatus(status)) {
            case 'Unscheduled':
            case 'On The Way':
            case 'On Site':
                return true;
            default:
                return false;
        }
    };

    const isWrenchLocked = (status) => {
        switch (parseTripStatus(status)) {
            case 'Unscheduled':
            case 'Complete':
                return true;
            default:
                return false;
        }
    };

    function daysSince(dateString) {
        // Parse the input date string
        const pastDate = new Date(dateString);
        
        // Get the current date
        const currentDate = new Date();
        
        // Calculate the difference in time (milliseconds)
        const timeDifference = currentDate - pastDate;
        
        // Convert the time difference from milliseconds to days
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        
        return daysDifference;
    }

    // Handle input changes
    const handleCustomerReportChange = (index, value) => {
        // Update the specific index of the input value
        const updatedValues = [...customerReports];
        updatedValues[index] = value; // Update value at the specific index
        setCustomerReports(updatedValues);
    };

    const handleInternalNotesChange = (index, value) => {
        // Update the specific index of the input value
        const updatedValues = [...internalNotes];
        updatedValues[index] = value; // Update value at the specific index
        setInternalNotes(updatedValues);
    }

    return (
        <div className="dataCard">
            {data.map((job, index) => {
                const currentJob = actionItems[job.id] || {};
                const currentTripIndex = currentJob.currentTripIndex || 0;
                const logo = `${window.location.protocol}//${window.location.hostname}:8443${job.client.logo}`;
                return (
                <div key={job.id} className="job-header-container">
                    <div className="job-header">
                        <div className="summary-row">
                        <div className="item" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={logo} style={{ width: '30px', marginRight: '10px' }} alt="logo" />
                            <span>Assign Date:<br /> {job.date_assigned}</span>
                        </div>

                            <div className="item collapseHeading clickable middle" onClick={() => toggleVisibility(job.id)}>
                                {visibleJobIds.includes(job.id) ? (
                                    <img src={collapseIcon} alt="collapse" title="Collapse" style={{"width": "25px"}} />
                                ) : (
                                    <img src={expandIcon} alt="expand" title="Expand" style={{"width": "25px"}} />
                                )}
                                <span className="collapseBadge">
                                    {visibleJobIds.includes(job.id) ? 'COLLAPSE' : 'EXPAND'}
                                </span>
                            </div>
                            
                        </div>

                        <div className="summary-row">
                            <div className="item lefty">Job # <span className="value">{job.job_number}</span></div>
                            <div className="item center">Days Open: {daysSince(job.date_assigned)}</div>
                            <div className="item righty">Status: <span className="statusBadge">{job.status}</span></div>
                        </div>

                        <div className="summary-details">
                            <div>
                                <div className="details-container">
                                    <div className="item lefty">{job.bill_to_name}</div>
                                    <div className="summary-details-amount righty">${job.amount}</div>
                                </div>

                                <div className="summary-details-row-2">
                                    <div className="address-row"><AddressLink address={job.job_site_address}/></div>
                                    <input type="checkbox"
                                        checked={getSelectedIds().includes(job.id)}
                                        onChange={(e) =>
                                            handleCheckboxChange(job, e.target.checked)
                                        } />
                                </div>

                                <div className="customer-reported-row">
                                    <div className="customer-reported-label">Customer Reported:</div>
                                    <input 
                                                type="text"
                                                id="customer_reported" 
                                                name="customer_reported"
                                                value={customerReports[index] || ""}    
                                                className="input customer-reported-input"
                                                style={{ textAlign: 'left' }}
                                                onChange={(e) => handleCustomerReportChange(index, e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const token = localStorage.getItem('access_token');
                                                        const url = `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/${job.id}/`
                                                        const method = 'PUT';

                                                        const updatedData = {
                                                            ...job,
                                                            customer_reported: e.target.value
                                                        }

                                                        fetch(url, { // Adjust this URL to your jobs endpoint
                                                            method: method,
                                                            headers: new Headers({
                                                                'Content-Type': 'application/json',
                                                                'Authorization': `Bearer ${token}`,
                                                            }),
                                                            body: JSON.stringify(updatedData),
                                                        })
                                                        .then(response => {
                                                            if (!response.ok) {
                                                                // Convert non-2xx HTTP responses into errors so the catch block can handle them
                                                                return response.json().then(data => Promise.reject(data));
                                                            }
                                                            return response.json();
                                                        })
                                                        .then(data => {
                                                            // notify(`Successfully Saved!`);
                                                            refreshTables();
                                                        })
                                                        .catch(error => {
                                                            let errorMessage = '';

                                                            Object.keys(error).forEach(key => {
                                                                // Check if the property is an array and has at least one message
                                                                if (Array.isArray(error[key]) && error[key].length > 0) {
                                                                    // Append the first error message of each field to the errorMessage string
                                                                    errorMessage += `${key}: ${error[key][0]}\n`;
                                                                }
                                                            });

                                                            //if (error.code == 'token_not_valid') {
                                                            //    logout();
                                                            //}

                                                            //notify(`Error adding job: ${errorMessage}`);
                                                            console.error('Error customer reported:', error);
                                                        });
                                                    }}
                                                }
                                            /> 
                                </div>
                                <div className="customer-reported-row">
                                    <div className="customer-reported-label">Internal Notes:</div>
                                    <input 
                                                type="text"
                                                id="internal_notes" 
                                                name="internal_notes"
                                                value={internalNotes[index] || ""}
                                                className="input customer-reported-input"
                                                style={{ textAlign: 'left' }}
                                                onChange={(e) => handleInternalNotesChange(index, e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const token = localStorage.getItem('access_token');
                                                        const url = `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/${job.id}/`
                                                        const method = 'PUT';

                                                        const updatedData = {
                                                            ...job,
                                                            internal_notes: e.target.value
                                                        }

                                                        fetch(url, { // Adjust this URL to your jobs endpoint
                                                            method: method,
                                                            headers: new Headers({
                                                                'Content-Type': 'application/json',
                                                                'Authorization': `Bearer ${token}`,
                                                            }),
                                                            body: JSON.stringify(updatedData),
                                                        })
                                                        .then(response => {
                                                            if (!response.ok) {
                                                                // Convert non-2xx HTTP responses into errors so the catch block can handle them
                                                                return response.json().then(data => Promise.reject(data));
                                                            }
                                                            return response.json();
                                                        })
                                                        .then(data => {
                                                            // notify(`Successfully Saved!`);
                                                            refreshTables();
                                                        })
                                                        .catch(error => {
                                                            let errorMessage = '';

                                                            Object.keys(error).forEach(key => {
                                                                // Check if the property is an array and has at least one message
                                                                if (Array.isArray(error[key]) && error[key].length > 0) {
                                                                    // Append the first error message of each field to the errorMessage string
                                                                    errorMessage += `${key}: ${error[key][0]}\n`;
                                                                }
                                                            });

                                                            //if (error.code == 'token_not_valid') {
                                                            //    logout();
                                                            //}

                                                            //notify(`Error adding job: ${errorMessage}`);
                                                            console.error('Error internal notes:', error);
                                                        });
                                                    }}
                                                }
                                            /> 
                                </div>
                            </div>
                        </div>

                        {visibleJobIds.includes(job.id) && (
                        <>
                            <hr class="dashed-line" />
                            <div className="summary-row">        
                                <div className="summary-details-scheduled-date">
                                    <div className="formGroup">
                                        
                                        <label htmlFor="scheduled_date">Scheduled Date:</label>
                                        <input 
                                            type="date" 
                                            id="scheduled_date"
                                            value={parseTimeWindow(job.trips[currentTripIndex].scheduled_date ? job.trips[currentTripIndex].scheduled_date : '').date}
                                            onChange={(e) => {
                                                const token = localStorage.getItem('access_token');
                                                const url = `${window.location.protocol}//${window.location.hostname}:8443/api/trips/${job.trips[currentTripIndex].id}/`
                                                const method = 'PUT';

                                                const updatedTripData = {
                                                    ...job.trips[currentTripIndex],
                                                    scheduled_date: JSON.stringify({
                                                        date: e.target.value,
                                                        start_time: parseTimeWindow(job.trips[currentTripIndex].scheduled_date).startTime || null,
                                                        end_time: parseTimeWindow(job.trips[currentTripIndex].scheduled_date).endTime || null
                                                    })
                                                }

                                                fetch(url, { // Adjust this URL to your jobs endpoint
                                                    method: method,
                                                    headers: new Headers({
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`,
                                                    }),
                                                    body: JSON.stringify(updatedTripData),
                                                })
                                                .then(response => {
                                                    if (!response.ok) {
                                                        // Convert non-2xx HTTP responses into errors so the catch block can handle them
                                                        return response.json().then(data => Promise.reject(data));
                                                    }
                                                    return response.json();
                                                })
                                                .then(data => {
                                                    // notify(`Successfully Saved!`);
                                                    refreshTables();
                                                })
                                                .catch(error => {
                                                    let errorMessage = '';

                                                    Object.keys(error).forEach(key => {
                                                        // Check if the property is an array and has at least one message
                                                        if (Array.isArray(error[key]) && error[key].length > 0) {
                                                            // Append the first error message of each field to the errorMessage string
                                                            errorMessage += `${key}: ${error[key][0]}\n`;
                                                        }
                                                    });

                                                    //if (error.code == 'token_not_valid') {
                                                    //    logout();
                                                    //}

                                                    //notify(`Error adding job: ${errorMessage}`);
                                                    console.error('Error adding job:', error);
                                                });
                                            }}
                                            className="input"
                                        />
                                    </div>
                                    <div className="time-container">
                                        <div className="time-input">
                                            <label htmlFor="scheduled_start_time">Arrival Time:</label>
                                            <input
                                                type="time"
                                                id="scheduled_start_time"
                                                value={parseTimeWindow(job.trips[currentTripIndex].scheduled_date).startTime}
                                                onChange={(e) => {
                                                    const token = localStorage.getItem('access_token');
                                                    const url = `${window.location.protocol}//${window.location.hostname}:8443/api/trips/${job.trips[currentTripIndex].id}/`
                                                    const method = 'PUT';

                                                    const updatedTripData = {
                                                        ...job.trips[currentTripIndex],
                                                        scheduled_date: JSON.stringify({
                                                            date: parseTimeWindow(job.trips[currentTripIndex].scheduled_date).date || null,
                                                            start_time: e.target.value + ':00',
                                                            end_time: parseTimeWindow(job.trips[currentTripIndex].scheduled_date).endTime || null
                                                        })
                                                    }

                                                    fetch(url, { // Adjust this URL to your jobs endpoint
                                                        method: method,
                                                        headers: new Headers({
                                                            'Content-Type': 'application/json',
                                                            'Authorization': `Bearer ${token}`,
                                                        }),
                                                        body: JSON.stringify(updatedTripData),
                                                    })
                                                    .then(response => {
                                                        if (!response.ok) {
                                                            // Convert non-2xx HTTP responses into errors so the catch block can handle them
                                                            return response.json().then(data => Promise.reject(data));
                                                        }
                                                        return response.json();
                                                    })
                                                    .then(data => {
                                                        // notify(`Successfully Saved!`);
                                                        refreshTables();
                                                    })
                                                    .catch(error => {
                                                        let errorMessage = '';

                                                        Object.keys(error).forEach(key => {
                                                            // Check if the property is an array and has at least one message
                                                            if (Array.isArray(error[key]) && error[key].length > 0) {
                                                                // Append the first error message of each field to the errorMessage string
                                                                errorMessage += `${key}: ${error[key][0]}\n`;
                                                            }
                                                        });

                                                        //if (error.code == 'token_not_valid') {
                                                        //    logout();
                                                        //}

                                                        //notify(`Error adding job: ${errorMessage}`);
                                                        console.error('Error adding job:', error);
                                                    });
                                                }}
                                                className="input"
                                            />
                                        </div>
                                        <div className="time-input">
                                            <label htmlFor="scheduled_duration">Window of Arrival:</label>
                                            {console.log(job.trips[currentTripIndex].duration)}
                                            <select
                                                type="time"
                                                id="scheduled_duration"
                                                value={job.trips[currentTripIndex].duration === null ? '---' : job.trips[currentTripIndex].duration}
                                                onChange={(e) => {
                                                    const token = localStorage.getItem('access_token');
                                                    const url = `${window.location.protocol}//${window.location.hostname}:8443/api/trips/${job.trips[currentTripIndex].id}/`
                                                    const method = 'PUT';

                                                    const updatedTripData = {
                                                        ...job.trips[currentTripIndex],
                                                        duration: e.target.value
                                                    }

                                                    if (e.target.value === '---') {
                                                        return;
                                                    }

                                                    fetch(url, { // Adjust this URL to your jobs endpoint
                                                        method: method,
                                                        headers: new Headers({
                                                            'Content-Type': 'application/json',
                                                            'Authorization': `Bearer ${token}`,
                                                        }),
                                                        body: JSON.stringify(updatedTripData),
                                                    })
                                                    .then(response => {
                                                        if (!response.ok) {
                                                            // Convert non-2xx HTTP responses into errors so the catch block can handle them
                                                            return response.json().then(data => Promise.reject(data));
                                                        }
                                                        return response.json();
                                                    })
                                                    .then(data => {
                                                        // notify(`Successfully Saved!`);
                                                        refreshTables();
                                                    })
                                                    .catch(error => {
                                                        let errorMessage = '';

                                                        Object.keys(error).forEach(key => {
                                                            // Check if the property is an array and has at least one message
                                                            if (Array.isArray(error[key]) && error[key].length > 0) {
                                                                // Append the first error message of each field to the errorMessage string
                                                                errorMessage += `${key}: ${error[key][0]}\n`;
                                                            }
                                                        });

                                                        //if (error.code == 'token_not_valid') {
                                                        //    logout();
                                                        //}

                                                        //notify(`Error adding job: ${errorMessage}`);
                                                        console.error('Error adding job:', error);
                                                    });

                                                    console.log(job.trips[currentTripIndex].duration);  
                                                }}
                                                className="input"
                                            >
                                                <option value="---">---</option>
                                                <option value="1HR">1 hour</option>
                                                <option value="2HR">2 hours</option>
                                                <option value="3HR">3 hours</option>
                                                <option value="4HR">4 hours</option>
                                                <option value="5HR">5 hours</option>
                                                <option value="6HR">6 hours</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="summary-details-completion-date righty">
                                    <div className="formGroup">
                                        Completion Date:<br /> 
                                        {job.status === 'IPG' ? (
                                            <input
                                                type="date"
                                                id="completion_date"
                                                name="completion_date"
                                                value={job.completion_date}
                                                onChange={(event) => handleChangeCompletionDate(event, job)}
                                                className="input"
                                            />
                                        ) : (
                                            'TBD'
                                        )}
                                    </div>
                                    <div className="formGroup">
                                        Est Days:<br />
                                        <input 
                                            type="number"
                                            id="estimated_days" 
                                            name="estimated_days"
                                            value={job.estimated_days}
                                            className="input"
                                            style={{ textAlign: 'right' }}
                                            onChange={(e) => {
                                                const token = localStorage.getItem('access_token');
                                                const url = `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/${job.id}/`
                                                const method = 'PUT';

                                                const updatedData = {
                                                    ...job,
                                                    estimated_days: e.target.value
                                                }

                                                fetch(url, { // Adjust this URL to your jobs endpoint
                                                    method: method,
                                                    headers: new Headers({
                                                        'Content-Type': 'application/json',
                                                        'Authorization': `Bearer ${token}`,
                                                    }),
                                                    body: JSON.stringify(updatedData),
                                                })
                                                .then(response => {
                                                    if (!response.ok) {
                                                        // Convert non-2xx HTTP responses into errors so the catch block can handle them
                                                        return response.json().then(data => Promise.reject(data));
                                                    }
                                                    return response.json();
                                                })
                                                .then(data => {
                                                    // notify(`Successfully Saved!`);
                                                    refreshTables();
                                                })
                                                .catch(error => {
                                                    let errorMessage = '';

                                                    Object.keys(error).forEach(key => {
                                                        // Check if the property is an array and has at least one message
                                                        if (Array.isArray(error[key]) && error[key].length > 0) {
                                                            // Append the first error message of each field to the errorMessage string
                                                            errorMessage += `${key}: ${error[key][0]}\n`;
                                                        }
                                                    });

                                                    //if (error.code == 'token_not_valid') {
                                                    //    logout();
                                                    //}

                                                    //notify(`Error adding job: ${errorMessage}`);
                                                    console.error('Error adding job:', error);
                                                });
                                            }}
                                        /> 
                                    </div>
                                    <div>
                                        Est Cmpln:<br />
                                        {addDaysToDate(job.date_assigned, job.estimated_days)}
                                    </div>
                                </div>
                        </div>
                        
                        <hr class="dashed-line" />

                        <div className="horizontal-container">
                            <div>
                                {job.trips.length > 0 ? (
                                    <>
                                        <img src={chevronLeft} alt="-1" className="subtract-trip" onClick={() => {}} style={{display: 'none'}} />
                                        <img src={bluepin} alt="" className="icon" />
                                        <img src={chevronRight} alt="+1" className="add-trip" onClick={() => {}} style={{display: 'none'}} />
                                        <br /> {getOrdinal(job.trips.length)} trip
                                        <br /> <span className="statusBadge">{parseTripStatus(job.trips[currentTripIndex].status)}</span>
                                    </>
                                ) : (
                                    <>
                                        <img src={chevronLeft} alt="-1" className="subtract-trip" style={{display: 'none'}} />
                                        <img src={redpin} alt="" className="icon" />
                                        <img src={chevronRight} alt="+1" className="add-trip" onClick={() => {}} style={{display: 'none'}} />
                                        <br /> trip needed
                                    </>
                                )}
                            </div>

                            <div>
                                <AnimatedGif 
                                    gifSrc={keyTurning} 
                                    firstFrameSrc={keyTopFrame} 
                                    lastFrameSrc={keyBottomFrame} 
                                    customClass="keyImage"
                                    locked={isKeyLocked(job.trips[currentTripIndex].status)}
                                    callback={keyClickCallback}
                                    job={job}
                                    currentStatus={parseTripStatus(job.trips[currentTripIndex].status) === 'On The Way'}
                                    currentTripIndex={currentTripIndex}
                                    buttonText={getKeyButtonText(parseTripStatus(job.trips[currentTripIndex].status))}
                                />
                            </div>

                            <div>
                                <AnimatedGif 
                                    gifSrc={wrenchTurning} 
                                    firstFrameSrc={wrenchTopFrame} 
                                    lastFrameSrc={wrenchBottomFrame} 
                                    customClass="wrenchImage"
                                    locked={isWrenchLocked(job.trips[currentTripIndex].status)}
                                    callback={wrenchClickCallback}
                                    job={job}
                                    currentStatus={false}
                                    currentTripIndex={currentTripIndex}
                                    buttonText={getWrenchButtonText(parseTripStatus(job.trips[currentTripIndex].status))}
                                />
                            </div>
                        </div>

                        <hr class="dashed-line" />

                        <div className="horizontal-container">
                            <div>
                                <div className="icon-container icon-border">
                                    <img 
                                        src={documentIcon} 
                                        alt="Documents" 
                                        className="icon" 
                                        onClick={() => openDocumentListModal(job.documents)}
                                    />
                                    <span className={`value badge ${job.documents.length > 0 ? 'green' : 'red'}`}>
                                        {job.documents.length}
                                    </span>
                                </div>

                                <div className='addDocButton'>
                                    <button onClick={openDocumentFormModal} className={`addDocumentButton ${job.documents.length > 0 ? 'green' : 'red'}`}>+</button>
                                </div>
                            </div>

                            <div>
                                <div className="icon-container icon-border">
                                    <img 
                                        src={photoIcon} 
                                        alt="Photos" 
                                        className="icon"
                                        onClick={() => openPhotoCarouselModal(job.photos)} 
                                    />
                                    <span className={`value badge ${job.photos.length > 0 ? 'green' : 'red'}`}>
                                        {job.photos.length}
                                    </span>
                                </div>
                                <div className='addDocButton'>
                                    <button onClick={openPhotoFormModal} className={`addDocumentButton ${job.photos.length > 0 ? 'green' : 'red'}`}>+</button>
                                </div>
                            </div>

                            <div>
                                <div className="icon-container icon-border">
                                    <img 
                                        src={blueprintIcon} 
                                        alt="Site Plans" 
                                        className="icon"
                                        onClick={() => openBluePrintsListModal(job.blueprints)} 
                                    />
                                    <span className={`value badge ${job.blueprints.length > 0 ? 'green' : 'red'}`}>
                                        {job.blueprints.length}
                                    </span>
                                </div>
                                <div className='addDocButton'>
                                    <button onClick={openBluePrintFormModal} className={`addDocumentButton ${job.blueprints.length > 0 ? 'green' : 'red'}`}>+</button>
                                </div>
                            </div>
                        </div>
                        
                        <hr class="dashed-line" />

                        <div className="trip-note-row">
                            {job.trips.map((trip, key) => (
                                trip.completion_note && (
                                    <div key={key}>
                                        <span>Trip {parseInt(key, 10) + 1}:</span><br />
                                        <span>{trip.completion_note}</span>
                                    </div>
                                )
                            ))}
                        </div>

                        <div className="metric-row">
                            <div className="metric-type"><b>Metric</b> <button className="green smallButton" onClick={openMetricModal}>+</button></div>
                            <div className="vicinity"><b>Vicinity</b></div>
                            <div className="specific-location"><b>Specific Location</b></div>
                        </div>

                        {job.metrics.map((metric, key) => (
                            <div className="metric-row">
                                <div className="metric-type">{metric.metric_type === "7" ? metric.other_metric_type : metric.metric_type.name}</div>
                                <div className="vicinity">{metric.vicinity.name}</div>
                                <div className="specific-location">{metric.specific_location === "15" ? metric.other_specific_location : metric.specific_location.name}</div>
                            </div>
                        ))}
                        
                        
                            <div className="edit-form-section">
                                <EditJob jobData={job} refreshTables={refreshTables} />
                            </div>
                        </>
                    )}
                </div>
                    <Modal
                        isOpen={metricModalIsOpen}
                        onRequestClose={closeMetricModal}
                        contentLabel='Add New Metric'
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: '4000',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '5000',
                                overflow: 'auto',
                                maxHeight: '80%',
                                width: '90%',  // Responsive width
                            }
                        }}
                    >
                        <h2>New Metric <button onClick={closeMetricModal} className="closeButton">Close</button></h2>
                        <MetricForm job={job} onSuccess={closeMetricModal} />
                    </Modal>

                    <Modal
                        isOpen={documentFormModalIsOpen}
                        onRequestClose={closeDocumentFormModal}
                        contentLabel="Add New Job Document"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: '4000',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '5000',
                                overflow: 'auto',
                                maxHeight: '80%',
                                width: '90%',  // Responsive width
                            }
                        }}
                    >
                        <h2>New Job Document <button onClick={closeDocumentFormModal} className="closeButton">Close</button></h2>
                        
                        <JobDocumentForm jobData={job} refreshTables={refreshTables} />
                    </Modal>

                    <Modal
                        isOpen={blueprintsFormModalIsOpen}
                        onRequestClose={closeBluePrintFormModal}
                        contentLabel="Add New Site Plans"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: '4000',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '5000',
                                overflow: 'auto',
                                maxHeight: '80%',
                                width: '90%',  // Responsive width
                            }
                        }}
                    >
                        <h2>New Site Plans <button onClick={closeBluePrintFormModal} className="closeButton">Close</button></h2>
                        
                        <JobBlueprintsForm jobData={job} refreshTables={refreshTables} />
                    </Modal>

                    <Modal
                        isOpen={photoFormModalIsOpen}
                        onRequestClose={closePhotoFormModal}
                        contentLabel="Add New Job Photo"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: '4000',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '5000',
                                overflow: 'auto',
                                maxHeight: '80%',
                                width: '90%',  // Responsive width
                            }
                        }}
                    >
                        <h2>New Job Photo <button onClick={closePhotoFormModal} className="closeButton">Close</button></h2>
                        
                        <JobPhotoForm jobData={job} refreshTables={refreshTables} />
                    </Modal>

                    <Modal
                        isOpen={tripNoteModalIsOpen}
                        onRequestClose={closeTripNoteModal}
                        contentLabel="Add New Trip Note"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: '4000',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '5000',
                                overflow: 'auto',
                                maxHeight: '80%',
                                width: '90%',  // Responsive width
                            }
                        }}
                    >
                        <h2>New Trip Note <button onClick={closeTripNoteModal} className="closeButton">Close</button></h2>
                        
                        <TripNoteForm tripData={job.trips[currentTripIndex]} refreshTables={refreshTables} closeTripNoteModal={closeTripNoteModal} />
                    </Modal>

                    <Modal
                        isOpen={documentListModalIsOpen}
                        onRequestClose={closeDocumentListModal}
                        contentLabel="Job Documents List"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: '4000',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '5000',
                                overflow: 'auto',
                                maxHeight: '80%',
                                width: '90%',  // Responsive width
                            }
                        }}
                    >
                        <h2>Job Documents <button onClick={closeDocumentListModal} className="closeButton">Close</button></h2>
                        <ul>
                            {currentJobDocuments.map(doc => (
                                <li key={doc.id}>
                                    <a href={doc.document_url} download target="_blank" rel="noopener noreferrer">
                                        {doc.name}
                                        {doc.description !== '' ? ' - ' + doc.description : ''}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Modal>

                    <Modal
                        isOpen={blueprintsListModalIsOpen}
                        onRequestClose={closeBluePrintsListModal}
                        contentLabel="Site Plans List"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: '4000',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '5000',
                                overflow: 'auto',
                                maxHeight: '80%',
                                width: '90%',  // Responsive width
                            }
                        }}
                    >
                        <h2>Site Plans <button onClick={closeBluePrintsListModal} className="closeButton">Close</button></h2>
                        <ul>
                            {currentJobBluePrints.map(doc => (
                                <li key={doc.id}>
                                    <a href={doc.blueprint_url} download target="_blank" rel="noopener noreferrer">
                                        {doc.name}
                                        {doc.description !== '' ? ' - ' + doc.description : ''}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Modal>

                    <Modal
                        isOpen={photoCarouselModalIsOpen}
                        onRequestClose={closePhotoCarouselModal}
                        contentLabel="Job Photos Carousel"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: '4000',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '5000',
                                overflow: 'auto',
                                maxHeight: '80%',
                                width: '90%',  // Responsive width
                            }
                        }}
                    >
                        <h2>Job Photos <button onClick={closePhotoCarouselModal} className="closeButton">Close</button></h2>
                        <Carousel>
                            {currentJobPhotos.map(photo => (
                                <div key={photo.id}>
                                    <img src={`${window.location.protocol}//${window.location.hostname}:8443${photo.photo}`} alt={photo.name} />
                                    <p className="legend">{photo.description}</p>
                                </div>
                            ))}
                        </Carousel>
                    </Modal>

                </div>
            );})}
        </div>
    );
};

export default JobData;
