import React, { useState } from "react";
import Modal from 'react-modal';
import "./index.css";

const Footer = () => {
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(false);

  const openPrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(true);
  };

  const closePrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(false);
  };

  return (
    <footer>
      <p>
        <button onClick={openPrivacyPolicyModal} className="privacy-policy-link">
          Privacy Policy
        </button>
        <br />
        © {new Date().getFullYear()} All Jobs Manager, all rights reserved.
      </p>

      <Modal
        isOpen={isPrivacyPolicyModalOpen}
        onRequestClose={closePrivacyPolicyModal}
        contentLabel="Privacy Policy"
      >
        <h2>Privacy Policy</h2>
        <div>
            <p>Effective Date: 01/01/2025</p>
            <p>
                At <strong>All Jobs Manager</strong>, your privacy is a top priority. This Privacy Policy explains how we collect, use, and protect your personal information, particularly in relation to text messaging services through Twilio and email communication through Mailgun.
            </p>

            <h2>Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
                <li><strong>Personal Information:</strong> Your name, email address, phone number, and other contact details provided when you sign up or use our services.</li>
                <li><strong>Communication Data:</strong> The content of messages sent via our platform, including text messages and emails.</li>
                <li><strong>Usage Data:</strong> Information about how you interact with our services, including IP address, device type, and browser type.</li>
            </ul>

            <h2>How We Use Your Information</h2>
            <p>We use your information for the following purposes:</p>
            <ul>
                <li>To deliver text messages and emails through Twilio and Mailgun, as per your consent.</li>
                <li>To communicate service updates, notifications, and promotional offers.</li>
                <li>To comply with legal obligations and ensure the security of our platform.</li>
            </ul>

            <h2>Your Consent for Communication</h2>
            <p>
                By providing your phone number and email address, you consent to receive communications from us. This includes:
            </p>
            <ul>
                <li><strong>Text Messages:</strong> Service alerts, reminders, and promotional messages sent via Twilio. Standard message and data rates may apply.</li>
                <li><strong>Emails:</strong> Service-related emails, newsletters, and promotional offers sent via Mailgun.</li>
            </ul>
            <p>
                You can opt-out of promotional messages at any time by replying <strong>STOP</strong> to text messages or clicking the "unsubscribe" link in our emails.
            </p>

            <h2>Data Sharing and Third Parties</h2>
            <p>We do not sell your personal information. However, we may share your data with third parties to facilitate our services:</p>
            <ul>
                <li><strong>Twilio:</strong> For sending and managing text messages.</li>
                <li><strong>Mailgun:</strong> For managing email communications.</li>
                <li><strong>Legal Compliance:</strong> If required to comply with laws or respond to valid legal requests.</li>
            </ul>

            <h2>Data Security</h2>
            <p>
                We implement appropriate security measures to protect your data against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet is 100% secure.
            </p>

            <h2>Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Access the personal data we hold about you.</li>
                <li>Request corrections to your personal data.</li>
                <li>Request deletion of your personal data, subject to our legal obligations.</li>
                <li>Opt-out of receiving promotional communications.</li>
            </ul>

            <h2>Contact Us</h2>
            <p>
                If you have questions about this Privacy Policy or our data practices, please contact us at:
            </p>
            <address>
                All Jobs Manager<br />
                Email: <a href="mailto:support@alljobsmanager.com">support@alljobsmanager.com</a><br />
                Phone: [Insert Phone Number]
            </address>

            <h2>Updates to This Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will be effective upon posting the revised policy on our website.
            </p>
        </div>
        <button onClick={closePrivacyPolicyModal}>Close</button>
      </Modal>
    </footer>
  );
};

export default Footer;
