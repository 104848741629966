import React, { useState, useEffect } from 'react';
import bluePin from './bluepin.png';
import './index.css';

const AddressLink = ({ address }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
    // Check if the Web Share API is available, which typically indicates a mobile device
    if (navigator.share) {
        setIsMobile(true);
    }
    }, []);

    // Utility function to determine if the device is iOS
    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    // Function to generate the map URL based on the address
    const generateMapLink = (address) => {
        const encodedAddress = encodeURIComponent(address);
        if (isIOS()) {
            // Use Apple Maps for iOS
            return `https://maps.apple.com/?q=${encodedAddress}`;
        } else {
            // Use Google Maps for other devices
            return `https://www.google.com/maps/search/${encodedAddress}`;
        }
    };

    const handleShare = (address) => {
        const url = generateMapLink(address);

        if (navigator.share) {
            navigator.share({
            title: 'Send the Map URL',
            text: `Map for ${address}`,
            url: url,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        } else {
            alert('Sharing is not supported in your browser.');
        }
    };

    const copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
          alert(`${textToCopy} copied to clipboard`);
        }).catch((err) => {
          console.error('Failed to copy: ', err);
        });
      };

    const mapLink = generateMapLink(address);

    return (
        <div>
            <span className="clickable" title="Copy address to clipboard" onClick={() => copyText(address)}>&#128203;</span>
            <a className="address_text" href={mapLink} target="_blank" rel="noopener noreferrer" title="Map" >
                {address}
            </a>
        </div>
    );
};

export default AddressLink;